import React, {FC, useState, useEffect, useContext} from 'react';
import {useHistory, useLocation} from 'react-router';

import Review from './Review';
import ReturningResult from './ReturningResult';
import {getCollectedFormFields} from '../../requests';
import {Checkbox, CheckboxField, Fullname, Select} from '../../Components';
import {StateContext} from '../../state';
import Icons from '../../Icons';
import {Option, SampleType} from '../../types';
import './style.css';
import {
    collectedFrom,
    getCollectedFromItem,
    getResultsProvision,
    getYesNoDontKnowItem,
    getYesNoItem
} from '../../helpers';

type ILocation = {
    state: any;
    sample: any;
}

type IProps = {
    onStep: (step: number) => void;
    onCancelRoute?: (route: string) => void;
}

const YesNoDontKnow = [
    {id: 1, text: "Yes"},
    {id: 2, text: "No"},
    {id: 3, text: "Don't know"}
];

const YesNoDontKnowExtended = [
    ...YesNoDontKnow,
    {id: 4, text: "Don't have any of this drug left"}
]

const ResultsProvision = [
    {id: 1, text: "In person"},
    {id: 2, text: "By phone"},
    {id: 3, text: "By email"},
    {id: 4, text: "Online"}
]

const CollectionSurvey: FC<IProps> = (props: IProps) => {
    const {dispatch} = useContext(StateContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);
    const [form, setForm] = useState<any>({});
    const [options, setOptions] = useState<any>({});
    const [fullname, setFullname] = useState<any>();
    const [showDate, setShowDate] = useState<boolean>(true);
    const history = useHistory();
    const location = useLocation<ILocation>();

    useEffect(() => {
        setLoading(true);
        getCollectedFormFields().then(res => {
            setOptions(res.data);
            setShowDate(res.data.show_date);

            setLoading(false);
        })
    }, [dispatch, history]);

    useEffect(() => {
        props.onStep(step);
        // eslint-disable-next-line
    }, [step])

    const setItem = (field: string, ops: Option[], value: string) => {
        let _value = value;
        let splitted = undefined;
        if (value && value.includes(": ")) {
            splitted = value.split(":");
            _value = splitted[0].trim();
        }
        const item = ops.find((op: Option) => op.text === _value)
        if (item) {
            if (splitted) {
                if (field !== "come_from") {
                    item.main = item.text;
                    item.text = splitted[1].trim();
                } else {
                    setField("come_from_other", splitted[1].trim());
                }
            }
            setField(field, item);
        }
    }

    const setItems = (field: string, ops: Option[], values: string[]) => {
        const items: Option[] = []
        values && values.length > 0 && values.forEach((drug: string) => {
            let _drug = drug;
            let splitted = undefined;
            if (drug.includes(": ")) {
                splitted = drug.split(":");
                _drug = splitted[0].trim();
            }
            const expdrug = ops.find((drugItem: Option) => drugItem.text === _drug);
            if (expdrug) {
                if (splitted) {
                    expdrug.main = expdrug.text;
                    expdrug.text = splitted[1].trim();
                }
                items.push(expdrug);
            }
        })
        setField(field, items);
    }

    useEffect(() => {
        if (location.state && location.state.sample) {
            const smple = location.state.sample;
            const sampleTypeMap: Record<SampleType, string> = {
                [SampleType.PARAPHERNALIA]: 'equipment',
                [SampleType.SUBSTANCE]: 'drug',
            }
            setField('editing', true);
            setField('fullname', smple.collected_by);
            setField("collected_from", getCollectedFromItem(smple.collected_from));
            setField("agency", smple.agency_name);
            setField("used_before", getYesNoItem(smple.service_used_before));
            setField("accessed_before", getYesNoItem(smple.previously_accessed));
            setField("paraphernalia_reuse", getYesNoDontKnowItem(smple.paraphernalia_reuse));

            if (options && options.SampleType) {

                const sample_type = options.SampleType.find((st: any) => st.text.toLowerCase().includes(sampleTypeMap[smple.sample_type as SampleType]));

                setField("sample_type", sample_type);
            }


            setField("sample_id", {id: smple.sample_id, text: smple.sample_id})

            if (options.ExpectedDrugs) {
                setItems("expected_drugs", options.ExpectedDrugs, smple.expected_drugs);
            }

            if (options.SampleOrigin) {
                setItem("come_from", options.SampleOrigin, smple.origin)
            }

            if (options.CheckingPurpose) {
                setItems("checking_purpose", options.CheckingPurpose, smple.checking_reasons);
            }

            if (options.SampleEffect) {
                setItems("sample_effects", options.SampleEffect, smple.sample_effect);
            }

            setField("wait_for_results", getYesNoDontKnowItem(smple.wait_for_results));
            setField("already_used", getYesNoDontKnowItem(smple.already_used))

            if (options.SampleColour) {
                setItems("sample_colours", options.SampleColour, smple.colour);
            }

            if (options.SampleTexture) {
                setItems("sample_textures", options.SampleTexture, smple.texture);
            }

            setField("results_provision", getResultsProvision(smple.results_provision));

            props.onCancelRoute && props.onCancelRoute(`/samples/${smple.sample_id}`);

            window.history.replaceState({}, document.title);
        }
        // eslint-disable-next-line
    }, [location.state, options])

    const setField = (name: string, value: any) => {
        setForm((old: any) => {
            return {...old, [name]: value};
        });
    }
    const updateItems = (name: string, selectedItem: any, action: 'push' | 'remove') => {
        setForm((old: any) => {
            let items: any[] = [];
            if (old[name]) {
                items = [...old[name]];
            }
            const index = items.findIndex(item => item.id === selectedItem.id);
            if (action === 'remove' && index > -1) {
                items.splice(index, 1);
            } else if (action === 'push') {
                if (["No", "Don’t know"].indexOf(selectedItem.text) > -1) {
                    return {...old, [name]: [selectedItem]};
                }
                items = items.filter(item => {
                    return ["No", "Don’t know"].indexOf(item.text) === -1;
                })
                if (index === -1) {
                    items.push(selectedItem);
                } else {
                    items[index] = selectedItem
                }
            }
            return {...old, [name]: [...items]};
        })
    }

    const isStaffFrom = form.collected_from && form.collected_from.id === "staff_from";
    const isServiceUser = form.collected_from && form.collected_from.id === "service_user";
    const showEffect =
        (form.already_used && form.already_used.text.toUpperCase() === "YES" && form.sample_type && form.sample_type.text === "Drug (vial)") ||
        (form.sample_type && form.sample_type.text !== "Drug (vial)") ||
        !isServiceUser
    ;

    const isStaffUser = form.collected_from && ["staff_behalf", "staff_behind"].includes(form.collected_from.id);

    const nextHandler = () => {
        setStep((old: number): any => {
            if (old < 7) {
                if ((old === 0 && !isServiceUser) || (old === 4 && isStaffUser) || (old===2 && !showEffect && !isServiceUser)) {
                    return old + 2;
                }
                return old + 1;
            }
            return old;
        })
        document.getElementById("topView")?.scrollIntoView({behavior: "smooth"});
    }

    const backHandler = () => {
        setStep((old: number): any => {
            if (old > 0) {
                if ((old === 2 && !isServiceUser) || (old === 6 && isStaffUser)) {
                    return old - 2;
                }
                return old - 1;
            }
            return old;
        })
        document.getElementById("topView")?.scrollIntoView({behavior: "smooth"});
    }

    const onDone = () => {
        dispatch({
            type: "showAlert", payload: {
                message: "Sample Successfully Added",
                status: `Sample ID: ${form.sample_id.id}`,
                view: () => {
                    history.push(`/samples/${form.sample_id.id}`);
                }
            }
        });
        history.replace(
            "/"
            //, {ResearchParticipation: isServiceUser ? form.sample_id.id : false}
        );
    }

    if (step === 6) {
        return (
            <Review
                form={form}
                onEdit={() => setStep(0)}
                onNext={nextHandler}
                onDone={onDone}
                onResult={(data: any) => setField("result", data)}
                permissions={{
                    isStaffFrom,
                    isServiceUser,
                    isStaffUser
                }}
            />
        )
    }

    if (step === 7) {
        return (
            <ReturningResult
                onDone={onDone}
                form={form}
                showDate={showDate}
            />
        )
    }

    if (loading) {
        return (<h1>Loading...</h1>)
    }

    const IsDisabled = (): boolean => {
        switch (step) {
            case 0:
                return !form.fullname ||
                    !form.collected_from ||
                    (isServiceUser && !form.editing && !form.terms) ||
                    (isStaffFrom && !form.agency)
            case 1:
            case 3:
            case 4:
                return false;
            case 2:
                return !form.sample_type ||
                    (form.sample_type && form.sample_type.text.toLowerCase() === "used equipment (baggie)" && form.collected_from.id === 'service_user' && form.paraphernalia_reuse === undefined) ||
                    !form.sample_id ||
                    !form.expected_drugs ||
                    form.expected_drugs.length < 1 ||
                    (form.sample_type && form.sample_type.text.toLowerCase() === "drug (vial)" && form.collected_from.id === 'service_user' && form.already_used === undefined)
            case 5:
                return !form.results_provision;
        }
        return true;
    }

    return (
        <form className="csform" onSubmit={(event) => {
            event.preventDefault();
            nextHandler();
        }}>
            {step === 0 &&
                <>
                    <div className="field">
                        <label className="label">
                            Full name of staff handling this sample: <span className="required">*</span>
                        </label>
                        {!form.editing &&
                            <Fullname
                                onChange={(fullname: string) => setField('fullname', fullname)}
                                onName={(_fname: string, _lname: string) => setFullname({
                                    firstName: _fname,
                                    lastName: _lname
                                })}
                                name={fullname}
                            />
                        }
                        {form.editing &&
                            <div className="bold mb">{form.fullname}</div>
                        }
                    </div>
                    <div className="field">
                        <label className="label">Sample is collected from: <span className="required">*</span></label>
                        <Select
                            required
                            value={form.collected_from}
                            items={collectedFrom}
                            onChange={(value) => setField('collected_from', value)}
                            searchable={false}
                        />
                    </div>
                    {isServiceUser && !form.editing &&
                        <div className="field">
                            <Checkbox
                                required
                                checked={!!form.terms}
                                onChange={value => setField('terms', value)}
                            >
                                The service user agrees to the <a
                                href="https://drugchecking.community/resource/terms-of-service/" target="_blank"
                                rel="noreferrer">terms</a> of this drug checking service.
                            </Checkbox>
                        </div>
                    }
                    {isStaffFrom &&
                        <div className="field">
                            <label className="label" htmlFor="agency">Name of agency this sample is coming from: <span
                                className="required">*</span></label>
                            <input
                                id="agency"
                                type="text"
                                required
                                value={form.agency}
                                onChange={(event) => setField('agency', event.target.value)}
                            />
                        </div>
                    }
                </>
            }
            {step === 1 &&
                <>
                    <div className="field">
                        <label className="label">Have you used this drug checking service before?</label>
                        <Select
                            value={form.used_before}
                            items={[
                                {id: 1, text: 'No'},
                                {id: 2, text: 'Yes'},
                            ]}
                            onChange={(value) => setField('used_before', value)}
                            searchable={false}
                        />
                    </div>
                    {form.used_before && form.used_before.id === 1 &&
                        <div className="field">
                            <label className="label">Have you previously accessed harm reduction services, such as drug
                                checking or supervised consumption?</label>
                            <Select
                                value={form.accessed_before}
                                items={[
                                    {id: 1, text: 'No'},
                                    {id: 2, text: 'Yes'},
                                ]}
                                onChange={(value) => setField('accessed_before', value)}
                                searchable={false}
                            />
                        </div>
                    }
                </>
            }
            {step === 2 &&
                <>
                    <div className="field">
                        <label className="label">Sample type: <span className="required">*</span></label>
                        <Select
                            required
                            value={form.sample_type}
                            items={options.SampleType}
                            onChange={(value) => setField('sample_type', value)}
                            searchable={false}
                        />
                    </div>
                    {isServiceUser && form.sample_type && form.sample_type.text === "Drug (vial)" &&
                        <div className="field">
                            <label className="label">Did you use this drug prior to submitting this sample? <span
                                className="required">*</span></label>
                            <Select
                                value={form.already_used}
                                items={YesNoDontKnow}
                                required={true}
                                onChange={(value) => setField('already_used', value)}
                                searchable={false}
                            />
                        </div>
                    }
                    {(form.sample_type && form.sample_type.text.toLowerCase() === "used equipment (baggie)" && form.collected_from.id === 'service_user' )&&
                        <div className="field">
                            <label className="label">Has this equipment been reused? <span className="required">*</span></label>
                            <Select
                                required
                                value={form.paraphernalia_reuse}
                                items={YesNoDontKnow}
                                onChange={(value) => setField('paraphernalia_reuse', value)}
                                searchable={false}
                            />
                        </div>
                    }
                    <div className="field">
                        <label className="label">Sample ID: <span className="required">*</span></label>
                        {form.editing && form.sample_id &&
                            <div className="bold mb">{form.sample_id.text}</div>
                        }
                        {!form.editing &&
                            <Select
                                required
                                value={form.sample_id}
                                items={form.sample_type ? options.Samples.filter((s: any) => {
                                    return s.container_type === (form.sample_type.text === "Drug (vial)" ? "VIAL" : "BAG");
                                }) : []}
                                onChange={(value) => setField('sample_id', value)}
                                nochevron
                                noItemsMessage="No sample IDs registered. Contact administrator."
                                notfoundError="The sample ID you entered is invalid. Please try again."
                            />
                        }
                    </div>
                    <CheckboxField
                        required
                        form={form.expected_drugs}
                        name="expected_drugs"
                        options={options.ExpectedDrugs}
                        question="What was the sample purchased as? Check all that apply."
                        updateItems={updateItems}
                    />

                </>
            }
            {step === 3 &&
                <>
                    {showEffect && <CheckboxField
                        form={form.sample_effects}
                        name="sample_effects"
                        options={options.SampleEffect}
                        question="Was this sample associated with an overdose or other unpleasant or abnormal effects? Check all that apply."
                        updateItems={updateItems}
                    />}
                    {isServiceUser &&
                        <CheckboxField
                            form={form.checking_purpose}
                            name="checking_purpose"
                            options={options.CheckingPurpose}
                            question="Why are you checking this sample? Check all that apply."
                            updateItems={updateItems}
                        />
                    }
                    {isServiceUser &&
                        <div className="field">
                            <label className="label">Are you planning to wait for your drug checking results before
                                using this drug, using it again, or selling it?</label>
                            <Select
                                value={form.wait_for_results}
                                items={YesNoDontKnowExtended}
                                onChange={(value) => setField('wait_for_results', value)}
                                searchable={false}
                            />
                        </div>
                    }
                </>
            }
            {step === 4 &&
                <>
                    <CheckboxField
                        form={form.sample_colours}
                        name="sample_colours"
                        options={options.SampleColour}
                        question="Sample colour: Check all that apply."
                        updateItems={updateItems}
                    />
                    {(form.sample_type && form.sample_type.text === "Drug (vial)") && <CheckboxField
                        form={form.sample_textures}
                        name="sample_textures"
                        options={options.SampleTexture}
                        question="Sample texture: Check all that apply."
                        updateItems={updateItems}
                    />}
                </>
            }
            {step === 5 && !isStaffUser &&
                <div className="field">
                    <label className="label">How do you wish to receive your results? <span
                        className="required">*</span></label>
                    <Select
                        required
                        value={form.results_provision}
                        items={ResultsProvision}
                        onChange={(value) => setField('results_provision', value)}
                        searchable={false}
                    />
                </div>
            }
            <div className="field buttons">
                {step > 0 &&
                    <button type="button" className="btn btn2 mr-1" onClick={backHandler}>Back</button>
                }
                <button tabIndex={1} type="submit" disabled={IsDisabled()} className="btn btn1">Next <Icons.ArrowRight
                    style={{marginLeft: '0.625rem'}}/></button>
            </div>
        </form>
    )
}

export default CollectionSurvey;
